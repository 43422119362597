import React from 'react'

export default function() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="GitBook">
        <g id="Group 30497">
          <path id="Vector" d="M10.6983 18.1756C11.1195 18.1756 11.3715 18.5291 11.3715 18.8814C11.3715 19.323 11.0343 19.5872 10.6983 19.5872C10.2772 19.5872 10.0252 19.2337 10.0252 18.8814C10.0252 18.5291 10.2772 18.1756 10.6983 18.1756ZM21.7274 13.6742C21.3063 13.6742 21.0543 13.3207 21.0543 12.9684C21.0543 12.6161 21.3914 12.2626 21.7274 12.2626C22.1486 12.2626 22.4006 12.6161 22.4006 12.9684C22.4006 13.3207 22.0634 13.6742 21.7274 13.6742ZM21.7274 10.6725C20.5492 10.6725 19.5389 11.7318 19.5389 12.9672C19.5389 13.2326 19.5389 13.4969 19.6229 13.673L12.4669 17.646C12.0457 17.0283 11.3726 16.6747 10.6983 16.6747C9.85603 16.6747 9.0989 17.2044 8.76175 17.9102L2.36291 14.3788C1.68977 14.0253 1.18462 12.7899 1.26862 11.7306C1.26862 11.2009 1.52062 10.7594 1.77377 10.5832C1.94176 10.4951 2.19491 10.4951 2.36291 10.5832H2.4469C4.13033 11.5544 9.77203 14.555 9.94003 14.7323C10.2772 14.9085 10.5292 14.9977 11.1183 14.6442L22.6526 8.37654C22.8206 8.28847 22.9897 8.11232 22.9897 7.8469C22.9897 7.4934 22.6526 7.31725 22.6526 7.31725C21.9794 6.96375 20.9691 6.52218 20.0429 5.99253C17.9383 4.93324 15.5806 3.78587 14.4863 3.25622C13.56 2.72657 12.8869 3.16815 12.7177 3.25622L12.4646 3.34429C7.75147 5.81638 1.43662 9.08234 1.10063 9.25969C0.426337 9.70127 0.0903429 10.4951 0.00634441 11.4664C-0.0776541 13.0553 0.679483 14.7323 1.85891 15.35L8.67775 19.0576C8.84575 20.2049 9.77203 21 10.7823 21C11.9606 21 12.9709 20.0288 12.9709 18.7933L20.464 14.5562C20.8852 14.9097 21.3063 14.9977 21.8114 14.9977C22.9897 14.9977 24 13.9384 24 12.703C23.832 11.6437 22.9057 10.6725 21.7263 10.6725" fill="currentColor" />
        </g>
      </g>
    </svg>

  )
}
