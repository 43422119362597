import React from 'react'

export default function() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Medium">
        <path id="Subtraction 6" d="M22.9597 20.4643H14.3296V20.0946L16.1079 18.3713C16.2821 18.1941 16.2821 18.1458 16.2821 17.8769V7.87789L11.3437 20.4258H10.6714L4.92272 7.87789V16.2868C4.89852 16.4612 4.91469 16.6389 4.97 16.8061C5.02531 16.9733 5.11827 17.1255 5.24172 17.2511L7.55111 20.0561V20.4228H1V20.0561L3.31061 17.2511C3.43163 17.1245 3.52157 16.9714 3.57332 16.8041C3.62507 16.6367 3.63723 16.4596 3.60883 16.2868V6.56278C3.62208 6.42969 3.60393 6.29534 3.55585 6.17053C3.50778 6.04572 3.43111 5.93393 3.332 5.84411L1.27683 3.36972V3H7.65378L12.5861 13.8106L16.9207 3H23V3.36972L21.2449 5.05211C21.1705 5.10845 21.1128 5.18405 21.0783 5.27076C21.0437 5.35746 21.0335 5.45196 21.0487 5.54406V17.9178C21.0332 18.0103 21.0433 18.1052 21.0779 18.1924C21.1125 18.2795 21.1702 18.3555 21.2449 18.4122L22.9597 20.0946V20.4613V20.4643Z" fill="currentColor" />
      </g>
    </svg>

  )
}
