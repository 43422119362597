import React from "react";
import { Link } from "react-router-dom";

const Illustration = () => {
  return (
    <svg
      style={{ width: "100%" }}
      xmlns="http://www.w3.org/2000/svg"
      width="508"
      height="308"
      fill="none"
      viewBox="0 0 508 308"
    >
      <g clipPath="url(#clip0_2972_19883)">
        <path fill="#2800BA" d="M266.285 5.144l.028-.012h-62.641v97.819h81.283V23.812l-18.67-18.668z"></path>
        <path
          fill="url(#paint0_linear_2972_19883)"
          d="M272.104.011l.028-.011h-62.636v97.818h81.277V18.68L272.104.01z"
        ></path>
        <path
          fill="#fff"
          d="M250.519 57.856c-9.836 0-17.833 7.997-17.833 17.833a2.872 2.872 0 002.864 2.87 2.877 2.877 0 002.87-2.87c0-6.67 5.428-12.098 12.099-12.098s12.099 5.427 12.099 12.098a2.872 2.872 0 002.865 2.87 2.877 2.877 0 002.87-2.87c0-9.83-7.997-17.833-17.834-17.833zM242.801 43.423l-6.983-6.983 6.983-6.982-3.634-3.64-6.988 6.983-6.983-6.983-3.639 3.64 6.983 6.982-6.983 6.983 3.639 3.639 6.983-6.983 6.988 6.983 3.634-3.64zM279.488 43.423L272.5 36.44l6.988-6.982-3.639-3.64-6.988 6.983-6.984-6.983-3.639 3.64 6.983 6.982-6.983 6.983 3.639 3.639 6.984-6.983 6.988 6.983 3.639-3.64z"
        ></path>
        <path
          fill="#5117FF"
          d="M228.634 166.419h-34.341v8.214h34.341v-8.214zM419.689 157.436H398.35v8.214h21.339v-8.214z"
        ></path>
        <path
          fill="#625DF5"
          d="M157.872 149.678h-26.851v8.215h26.851v-8.215zM294.402 149.656h-21.34v8.214h21.34v-8.214zM38.254 198.239v8.214h65.26l5.674-8.214H38.254z"
        ></path>
        <path fill="#FF7847" d="M281.941 189.234v8.214h31.522l5.673-8.214h-37.195z"></path>
        <path fill="#5117FF" d="M336.796 282.343h-48.151v8.214h48.151v-8.214z"></path>
        <path fill="#625DF5" d="M454.298 194.773h-59.665v10.293h59.665v-10.293z"></path>
        <path fill="#FF7847" d="M507.999 249.659h-59.665v8.214h59.665v-8.214z"></path>
        <path fill="#625DF5" d="M59.665 267.096H0v9.268h59.665v-9.268z"></path>
        <path fill="#5117FF" d="M90.674 244.643H55.553v9.268h35.121v-9.268z"></path>
        <path fill="#625DF5" d="M383.036 185.383v-8.214h-20.303l-5.456 8.214h25.759z"></path>
        <path fill="#FF7847" d="M101.273 173.257h-21.34v9.184h21.34v-9.184z"></path>
        <path
          fill="#5117FF"
          d="M463.878 222.737h-21.339v8.209h21.339v-8.209zM35.947 224.977h-21.34v10.265h21.34v-10.265z"
        ></path>
        <path
          fill="#625DF5"
          d="M209.268 282.371h-6.649v8.214h18.302c-1.343-.63-2.664-1.309-3.935-2.084a40.204 40.204 0 01-7.718-6.124v-.006zM149.944 296.52h-45.727V308h45.727v-11.48zM421.378 271.733h-21.339v8.214h21.339v-8.214zM184.084 242.359h-93.41v2.284h24.544v9.268H90.674v1.566h52.676v17.409h33.227v-17.409h13.498v-13.118H184.084z"
        ></path>
        <path fill="#fff" d="M115.218 244.643H90.674v9.268h24.544v-9.268z"></path>
        <path
          fill="#625DF5"
          d="M252.46 242.359c-.101 16.673-3.963 25.027-11.598 25.027-7.635 0-11.497-8.354-11.597-25.027h-34.386c.078 10.744 2.023 20.034 5.863 27.852 2.318 4.715 5.166 8.755 8.532 12.154h14.696v8.215h-3.037c5.957 2.803 12.595 4.224 19.935 4.224 8.995 0 16.953-2.101 23.869-6.314 6.916-4.208 12.333-10.304 16.251-18.284 3.84-7.819 5.785-17.109 5.863-27.853h-34.386l-.005.006z"
        ></path>
        <path
          fill="#fff"
          d="M223.964 282.371h-14.696c2.329 2.352 4.893 4.408 7.718 6.124a40.877 40.877 0 003.935 2.085h3.037v-8.215l.006.006z"
        ></path>
        <path
          fill="#625DF5"
          d="M294.402 242.359v13.118h52.677v17.409h33.226v-17.409h13.493v-13.118h-99.396zM184.083 228.923H170.59v-15.526h-32.212v15.526h-13.787l38.309-57.606h-35.116l-18.597 26.922h14.172v8.214h-19.846L84.687 233.71v8.649h99.396v-13.436z"
        ></path>
        <path fill="#fff" d="M123.359 198.239h-14.172l-5.673 8.214h19.845v-8.214z"></path>
        <path
          fill="#625DF5"
          d="M223.261 241.701c0-17.119 3.868-25.685 11.609-25.685s11.608 8.56 11.608 25.685c0 .229-.005.435-.011.658h34.386c0-.223.017-.435.017-.658 0-11.028-1.957-20.53-5.874-28.51-3.918-7.98-9.335-14.077-16.251-18.284-6.917-4.208-14.875-6.314-23.87-6.314-8.995 0-16.953 2.106-23.869 6.314-6.916 4.207-12.333 10.304-16.251 18.284-3.918 7.98-5.874 17.482-5.874 28.51 0 .223.011.435.017.658h34.385c0-.223-.011-.429-.011-.658h-.011zM387.811 228.923h-13.492v-15.526h-32.218v15.526h-13.782l28.958-43.54h-10.762v-8.214h16.218l3.895-5.852h-35.116l-12.377 17.917h12.076v8.214h-17.75l-25.045 36.262v8.649h99.395v-13.436z"
        ></path>
        <path
          fill="#fff"
          d="M331.211 189.234h-12.077l-5.673 8.214h17.75v-8.214zM346.516 185.383h10.761l5.456-8.214h-16.217v8.214z"
        ></path>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2972_19883"
          x1="250.135"
          x2="247.938"
          y1="94.323"
          y2="3.542"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.002" stopColor="#7B61FF"></stop>
          <stop offset="1" stopColor="#FF7847"></stop>
        </linearGradient>
        <clipPath id="clip0_2972_19883">
          <path fill="#fff" d="M0 0H508V308H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

function NotFound() {
  return (
    <section
      style={{
        margin: "20px auto",
        display: "flex",
        flexDirection: "column",
        gap: 30,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div>
        <Illustration />
      </div>
      <p style={{ maxWidth: "700px", textAlign: "center", fontSize: "24px", lineHeight: "34px", fontWeight: 500 }}>
        We’re sorry, the page you requested could not be found. Please go back to the homepage.
      </p>

      <Link
        to={"/trade"}
        style={{
          background: "#FF7847",
          padding: 16,
          textDecoration: "none",
          color: "black",
          width: 322,
          textAlign: "center",
          fontSize: "20px",
          lineHeight: "24px",
          fontWeight: 500,
          borderRadius: "10px",
        }}
      >
        Go Home
      </Link>
    </section>
  );
}

export default NotFound;
