import React from "react";

import "./Footer.css";
import { $reddit, $discord, $twitter2, $medium, $youtube, $telegram, $coinGecko } from "./assets/logos/mediaLogos";

import Logo from "./assets/logos/logo_GMX.svg";
// import { getImageUrl } from "./cloudinary/getImageUrl";


export default function Footer() {
  return (

    <div className="footer">        <div className="footer-logo">
    <a href="/">
      <img
        src={Logo}
        alt="Logo"
        style={{ height: 28, textAlign:'center', margin:'0 auto', display:'block' }}
      ></img>
    </a>

    <div className="flex items-center gap-3" style={{margin:'0 auto', display:'block'}}>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://discord.com/invite/aPvKNytvZT"
      >
        {$discord}
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://twitter.com/DPEX_io"
      >
        {$twitter2}
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://medium.com/@dpex"
      >
        {$medium}
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://telegram.me/dpex_io"
      >
        {$telegram}
      </a>
    </div>
  </div>



    </div>
  );
}
