let eventsData = [
  // {
  //   id: "kinetix-trade-verified",
  //   title: "kinetix Official Domains",
  //   isActive: true,
  //   validTill: "02/01/2023 12:00:00 PM",
  //   bodyText: "Please verify that you are on the correct domain. https://perps.kinetix.finance/",
  //   buttons: [
  //     {
  //       text: "app.kinetix.org",
  //       link: "https://app.kinetix.org",
  //     },
  //     {
  //       text: "perps.kinetix.finance",
  //       link: "https://perps.kinetix.finance",
  //     }
  //   ],
  // },
];
export default eventsData;
