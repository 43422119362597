import React from 'react'

export default function() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Discord">
        <path id="Vector" d="M19.6239 4.43289C18.2217 3.76967 16.7181 3.28103 15.1459 3.00117C15.1173 2.99577 15.0887 3.00927 15.0739 3.03627C14.8805 3.39082 14.6663 3.85335 14.5163 4.21691C12.8254 3.95595 11.1431 3.95595 9.48679 4.21691C9.33676 3.84527 9.11478 3.39082 8.92053 3.03627C8.90578 3.01017 8.87718 2.99667 8.84855 3.00117C7.27725 3.28014 5.7736 3.76877 4.37052 4.43289C4.35838 4.43829 4.34797 4.4473 4.34106 4.45899C1.48894 8.85132 0.707629 13.1357 1.09092 17.367C1.09265 17.3877 1.10392 17.4075 1.11953 17.42C3.00127 18.8445 4.82407 19.7093 6.61301 20.2826C6.64164 20.2916 6.67197 20.2808 6.69019 20.2565C7.11337 19.6608 7.49059 19.0326 7.81402 18.3721C7.83311 18.3334 7.81489 18.2875 7.77588 18.2722C7.17754 18.0383 6.6078 17.753 6.05975 17.429C6.0164 17.403 6.01293 17.339 6.05281 17.3084C6.16814 17.2194 6.2835 17.1267 6.39363 17.0331C6.41355 17.016 6.44131 17.0124 6.46474 17.0232C10.0652 18.7177 13.9631 18.7177 17.521 17.0232C17.5445 17.0115 17.5722 17.0151 17.593 17.0322C17.7032 17.1258 17.8185 17.2194 17.9347 17.3084C17.9746 17.339 17.972 17.403 17.9286 17.429C17.3806 17.7593 16.8108 18.0383 16.2116 18.2713C16.1726 18.2866 16.1553 18.3334 16.1744 18.3721C16.5047 19.0317 16.882 19.6598 17.2973 20.2556C17.3147 20.2808 17.3459 20.2916 17.3745 20.2826C19.1721 19.7093 20.9949 18.8445 22.8766 17.42C22.8931 17.4075 22.9035 17.3885 22.9053 17.3678C23.364 12.4761 22.1369 8.22681 19.6525 4.45988C19.6465 4.4473 19.6361 4.43829 19.6239 4.43289ZM8.35169 14.7906C7.26771 14.7906 6.37454 13.7647 6.37454 12.5049C6.37454 11.245 7.25039 10.2192 8.35169 10.2192C9.46163 10.2192 10.3462 11.254 10.3288 12.5049C10.3288 13.7647 9.45296 14.7906 8.35169 14.7906ZM15.6619 14.7906C14.5779 14.7906 13.6847 13.7647 13.6847 12.5049C13.6847 11.245 14.5606 10.2192 15.6619 10.2192C16.7718 10.2192 17.6563 11.254 17.639 12.5049C17.639 13.7647 16.7718 14.7906 15.6619 14.7906Z" fill="currentColor" />
      </g>
    </svg>

  )
}
