import React from 'react'

export default function() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Telegram">
        <path id="Vector" fill-rule="evenodd" clip-rule="evenodd" d="M4.27983 11.6419C9.27733 9.46457 12.6098 8.02914 14.2772 7.33561C19.0379 5.35545 20.0272 5.01148 20.6719 5.00012C20.8138 4.99762 21.1308 5.03277 21.3362 5.19943C21.5097 5.34016 21.5574 5.53026 21.5802 5.66368C21.603 5.7971 21.6315 6.10104 21.6089 6.33854C21.3509 9.04922 20.2346 15.6273 19.6667 18.6634C19.4264 19.948 18.9532 20.3788 18.4951 20.4209C17.4996 20.5125 16.7436 19.763 15.7794 19.131C14.2706 18.1419 13.4183 17.5262 11.9537 16.5611C10.2612 15.4458 11.3584 14.8328 12.323 13.8309C12.5754 13.5687 16.9617 9.57907 17.0466 9.21715C17.0572 9.17189 17.067 9.00316 16.9668 8.91407C16.8666 8.82497 16.7186 8.85544 16.6119 8.87967C16.4605 8.91402 14.0502 10.5071 9.3809 13.6591C8.69674 14.1289 8.07705 14.3578 7.52183 14.3458C6.90974 14.3325 5.73232 13.9997 4.85704 13.7152C3.78347 13.3662 2.93021 13.1817 3.00451 12.589C3.04321 12.2803 3.46832 11.9646 4.27983 11.6419Z" fill="currentColor" />
      </g>
    </svg>


  )
}
